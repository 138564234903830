.hero-section {
    height: 100vh;
    padding: 0 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #000;
}

.navbar-container {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
}

.hero-text {
    text-align: center;
    margin-bottom: 10vh;
    color: white;
}

.hero-text h1 {
    position: relative;
    display: inline;
    margin: 0;
    padding: 0;
    z-index: 3;
}

.key-text-container {
    position: relative;
    display: inline-block;
}

#key-text {
    position: relative;
    z-index: 1;
    text-shadow: 0px 0px 8px rgba(255,255,255, 0.70);
}

.mesh-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 534px;
    height: 212px;
    z-index: 2;
    mix-blend-mode: multiply;
    opacity: 1;
    pointer-events: none;
}
