@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;700&display=swap');

.navbar {
    position: relative;
    width: 100%;
    height: 78px;
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat Alternates', serif;
    font-style: normal;
}

#navbar-left {
    padding-left: 60px;
}

#navbar-right {
    padding-right: 60px;
}

.logo {
    margin-top: 51px;
    margin-right: 43px;
    font-size: 22px;
    font-weight: 700;
    white-space: nowrap;
}

a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7) !important;
}

.navbar-links {
    margin-top: 56px;
}

.navbar-links > a {
    margin-left: 43px;
}

.divider {
    margin-top: 66px;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.4);
}