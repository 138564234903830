@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: black;
}

.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1512px;
}

h1 {
  font-family: Poppins, serif;
  font-size: 82px;
  font-style: normal;
  font-weight: 700;
  line-height: 122.572%
}
